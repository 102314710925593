// require("./lib/pym");
require("waypoints/lib/noframework.waypoints.min");

var qsa = function(s, doc) { 
    if (!!doc)
        return Array.prototype.slice.call(doc.querySelectorAll(s));
    else
        return Array.prototype.slice.call(document.querySelectorAll(s));
};
var scrollingSections = qsa(".scrolling-section");
var images = qsa(".scrolly-image");

var indexed = [];

var svgFrames = qsa(".svg-iframe");

svgFrames.forEach(svg => {
    if (svg.contentDocument?.readyState  == 'complete') {
        loadSVG({target: svg});
    }
    if (svg.addEventListener)
        svg.addEventListener("load", loadSVG, false);
    else
        svg.attachEvent("onload", loadSVG);
})

images.forEach(function(img, i) {
    var index = parseInt(img.getAttribute("data-index"));
    if (index !== undefined)
        indexed[index] = img;
});

function loadSVG(evt) {
    var svg = evt.target;
    var svgDoc = svg.contentDocument;
    var svgEle = svgDoc.rootElement;
    var parent = svg.parentElement;

    if (!!parent) {
        var covers = qsa(".iframe-cover", parent);
        covers?.forEach(cover => {
            cover.classList.add("hidden");
        })
    }

    if (!svgEle) {
        // console.info("No element in iframe on load:");
        // console.info(svgDoc);
        return;
    }

    if (svg.classList.contains("mobile")) {
        svgEle.setAttribute("preserveAspectRatio", "xMidYMin meet");
        if (svg.classList.contains("centered"))
            svgEle.setAttribute("preserveAspectRatio", "xMidYMid meet");
    }
    else {
        svgEle.setAttribute("preserveAspectRatio", "xMidYMid slice");
        if (svg.classList.contains("contain"))
            svgEle.setAttribute("preserveAspectRatio", "xMidYMid meet");
    }

    svgEle.setAttribute("data-index", svg.getAttribute("data-index"));

    var clouds = qsa("g", svgDoc).filter(c => c.id.indexOf("Cloud_") == 0);
    var maze_items = qsa("g", svgDoc).filter(c => c.id.indexOf("Item_") == 0);
    var maze_arrows = qsa("g", svgDoc).filter(c => c.id.indexOf("Arrow_") == 0);
    var maze_pointers = qsa("g", svgDoc).filter(c => c.id.indexOf("Pointer_") == 0);
    var badge_catch = qsa("g", svgDoc).filter(c => c.id.indexOf("Badge_") == 0);

    clouds.forEach((cloud, i) => {
        var id = cloud.id;
        cloud.classList.add(id);
        cloud.classList.add("cloud");
    });
    
    maze_items.forEach((x, i) => {
        x.classList.add("maze-item");
        if (x.id.indexOf("badge") > -1)
            x.classList.add("badge");
    
        var itemType = x.id.substring(5);
        x.classList.add(itemType);
    });
    maze_arrows.forEach((x, i) => {
        x.classList.add("maze-arrow");
        if (i % 2 == 0)
            x.classList.add("reverse");
    });
    maze_pointers.forEach((x, i) => {
        x.classList.add("maze-pointer");
    });
    badge_catch.forEach((x, i) => {
        x.classList.add("maze-item");
        x.classList.add("badge");
    })

      
    var linkElm = svgDoc.createElementNS("http://www.w3.org/1999/xhtml", "link");
    linkElm.setAttribute("href", "/style.css");
    linkElm.setAttribute("type", "text/css");
    linkElm.setAttribute("rel", "stylesheet");
    svgEle?.appendChild(linkElm);

    var ix = parseInt(svg.getAttribute("data-index"));
    var section = scrollingSections.filter(s => parseInt(s.getAttribute("data-index")) == ix)[0];
    if (!!section) {
        var wp = new Waypoint({
            element: section,
            offset: '100%',
            handler: (direction) => {
                scrollSection(section, direction);
            }
        })

        // Special handling for "final boss" cards
        if (section.getAttribute("data-level") == 7) {
            var cards = qsa(".floating-card", section);
            cards.forEach(card => {
                new Waypoint({
                    element: card,
                    offset: '100%',
                    handler: (direction) => {
                        scrollGemCard(card, section, direction, svgEle);
                    }
                })
            })
        }
    }
}

function scrollGemCard(card, section, direction, svgEle) {
    var cardIndex = card.getAttribute("data-card");
    var gems = qsa(".Greenjewel, .Redjewel, .Bluejewel, .Purplejewel", svgEle);

    if (cardIndex == 0 || 
        cardIndex > 4 || 
        (cardIndex == 1 && direction == "up")){
        gems.forEach(gem => gem.classList.remove("faded"));
        return;
    }

    cardIndex--;
    gems.forEach(gem => gem.classList.add("faded"));
    if (direction == "down")
        gems[cardIndex].classList.remove("faded");
    else if (direction == "up")
        gems[cardIndex-1]?.classList.remove("faded");
}

function scrollSection(section, direction) {
    var ix = parseInt(section.getAttribute("data-index"));
    var img = indexed[ix];
    var prevImg = indexed[ix-1];

    if (!img)
        return;
    
    var frames = qsa("iframe", img);
    var prevFrames = !prevImg ? [] : qsa("iframe", prevImg);
    
    // if (ix == 0) {
    //     img.classList.add("is-visible");
    //     frames.forEach(f => f.contentDocument.rootElement?.classList.add("is-visible"));
    // }        
    
    if (direction == 'down') {
        img.classList.add("is-visible");
        frames.forEach(f => f.contentDocument.rootElement?.classList.add("is-visible"));
        prevImg?.classList.remove("is-visible");
        prevFrames.forEach(f => f.contentDocument.rootElement?.classList.remove("is-visible"));
    } else if (direction == 'up') {
        img.classList.remove("is-visible");
        frames.forEach(f => f.contentDocument.rootElement?.classList.remove("is-visible"));
        prevImg?.classList.add("is-visible");
        prevFrames.forEach(f => f.contentDocument.rootElement?.classList.add("is-visible"));
    }
}

var customSelect = require("custom-select").default
let filterSelect = customSelect("#filter-select")[0];
let allTopics = qsa(".topic");
filterSelect.select.addEventListener('change', (evt) => {
    if (!evt || !evt.target)
        return;
    var selectedCat = evt.target.value;
    console.log(`${evt.target.id} has changed it's value to ${selectedCat} 👌`);
    allTopics.forEach((topic) => {
        var category = topic.getAttribute("data-category");
        if (selectedCat == "all" || selectedCat == category) {
            topic.classList.remove("hidden");
        } else {
            topic.classList.add("hidden");
        }
    })
});

// const scrollyObs = new IntersectionObserver(sections => {
//     sections.forEach(section => {
//         var ix = section.target.getAttribute("data-index");
//         var img = indexed[ix];
//         var frames = qsa("iframe", img);
//         if (section.isIntersecting) {
//             img.classList.add("is-visible");
//             frames.forEach(f => f.contentDocument.rootElement?.classList.add("is-visible"));
//         } else {
//             img.classList.remove("is-visible");
//         frames.forEach(f => f.contentDocument.rootElement?.classList.remove("is-visible"));
//         }
//     })
// })
// var cards = qsa(".floating-card");
// cards.forEach(c => scrollyObs.observe(c));

var fadeInItems = qsa(".fade-into-view");
var slideInItems = qsa(".slide-into-view");

const visibleObs = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible');
    } else {
      entry.target.classList.remove('is-visible');
    }
  });
});

fadeInItems.forEach(item => {
  visibleObs.observe(item);
});
slideInItems.forEach(item => {
  visibleObs.observe(item);
});